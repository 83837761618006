/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, ColumnCover, ColumnDiv, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu js-anim  --anim3 --anim-s4 pb--08 pt--08" anim={"3"} menu={true} name={"menu_top"} animS={"4"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box --left" style={{"maxWidth":""}}>
              
              <Image src={"https://cdn.swbpg.com/o/9329/89560b4e6f4f4d74bab7d2ebfbccebfa.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":174}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style3">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 fs--18 swpf--uppercase" use={"page"} href={"/#menu_top"} target={""} content={"<span style=\"color: rgb(0, 0, 0); font-weight: 400;\">ÚVOD</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 fs--18 swpf--uppercase" use={"page"} href={"/#sluzby"} target={""} content={"<span style=\"font-weight: 400; color: rgb(0, 0, 0);\">SLUŽBY</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 fs--18 swpf--uppercase" use={"page"} href={"/#pracovnimista"} target={null} content={"<span style=\"color: rgb(0, 0, 0); font-weight: 400;\">VOLNÁ PRACOVNÍ MÍSTA</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 fs--18" use={"page"} href={"/#kontaktni_form"} target={""} content={"<span style=\"font-weight: normal;\">KONTAKT</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-n8j6so css-1jfxdkz --parallax pb--50" name={"uvod_obrazek"} animS={"2"} style={{"paddingTop":117,"marginBottom":0}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/9329/8ac65e22175e45af9dd763ab65a0cfea_ove=ff5000x8__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8ac65e22175e45af9dd763ab65a0cfea_ove=ff5000x8__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8ac65e22175e45af9dd763ab65a0cfea_ove=ff5000x8__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8ac65e22175e45af9dd763ab65a0cfea_ove=ff5000x8__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8ac65e22175e45af9dd763ab65a0cfea_ove=ff5000x8__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8ac65e22175e45af9dd763ab65a0cfea_ove=ff5000x8__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8ac65e22175e45af9dd763ab65a0cfea_ove=ff5000x8__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8ac65e22175e45af9dd763ab65a0cfea_ove=ff5000x8__s=3000x_.jpeg);
    }
  
background-position: 52% 83%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--shadow5 fs--102 w--800 title-box--invert ls--01 lh--12" style={{"maxWidth":1158,"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-weight: normal;\"><span style=\"background-color: rgba(255, 255, 255, 0);\">TECHNICKÁ ZAŘÍZENÍ BUDOV</span><br></span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--cColor2 btn-box--filling2 fs--22" use={"page"} href={"/#kontaktni_form"} style={{"backgroundColor":"rgba(245,100,30,1)"}} content={"ODESLAT POPTÁVKU"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--25">
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style2" content={"<span style=\"font-weight: normal;\">NAŠE SLUŽBY</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":994}} content={"<span style=\"font-weight: normal;\">Vyrábíme autorizované projektové dokumentace systémů vzduchotechniky, vytápění, chlazení a zdravotechniky<br><br>&nbsp;Provádíme měření a zaregulování vzduchotechnických a hydnonických systémů vytápění a chlazení<br><br>Řešíme optimalizace provozu, studie, analýzy alternativních variant systémů a diagnostiku závad<br><br>Zajišťujeme technický dozor stavebníka v oboru technická zařízení budov</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--4 flex--center" style={{"maxWidth":1500}} columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--center">
              
              <Image className="--shape2 --shadow2" src={"https://cdn.swbpg.com/t/9329/8438bdacaefe46208d932f178125eb6c_s=350x_.PNG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/9329/8438bdacaefe46208d932f178125eb6c_s=350x_.PNG 350w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim2 --anim-s3 flex--center" anim={"2"} animS={"3"}>
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--cColor2 btn-box--shadow4 btn-box--filling2 fs--22" use={"page"} href={"/"} style={{"maxWidth":260,"backgroundColor":"rgba(245,100,30,1)"}} target={null} content={"<span style=\"font-weight: normal;\">&nbsp;PROJEKTY&nbsp;<br>TZB</span>"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--shape2 --shadow2" src={"https://cdn.swbpg.com/t/9329/333331c1d38e4d388a147cb657fb4af8_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/9329/333331c1d38e4d388a147cb657fb4af8_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/9329/333331c1d38e4d388a147cb657fb4af8_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/9329/333331c1d38e4d388a147cb657fb4af8_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/9329/333331c1d38e4d388a147cb657fb4af8_s=1400x_.jpeg 1400w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim2 --anim-s3 flex--center" anim={"2"} animS={"3"}>
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--cColor2 btn-box--shadow4 btn-box--filling2 fs--22" use={"page"} href={"/"} style={{"maxWidth":260,"backgroundColor":"rgba(245,100,30,1)"}} target={null} content={"<span style=\"font-weight: normal;\">MĚŘENÍ A ZAREGULOVÁNÍ</span>"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--shape2 --shadow2" src={"https://cdn.swbpg.com/t/9329/59d9ba4c181d4a288ef36ab48721a3f4_s=660x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/9329/59d9ba4c181d4a288ef36ab48721a3f4_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/9329/59d9ba4c181d4a288ef36ab48721a3f4_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/9329/59d9ba4c181d4a288ef36ab48721a3f4_s=860x_.jpeg 860w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim2 --anim-s3 flex--center" anim={"2"} animS={"3"}>
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--cColor2 btn-box--shadow4 btn-box--filling2 fs--22" use={"page"} href={"/"} style={{"backgroundColor":"rgba(245,100,30,1)"}} target={null} content={"<span style=\"font-weight: normal;\">TECHNICKÝ DOZOR</span>"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--shape2 --shadow2" src={"https://cdn.swbpg.com/t/9329/bc17be9024c04f9fa7654387cb78bbd3_s=660x_.PNG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/9329/bc17be9024c04f9fa7654387cb78bbd3_s=350x_.PNG 350w, https://cdn.swbpg.com/t/9329/bc17be9024c04f9fa7654387cb78bbd3_s=660x_.PNG 660w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim2 --anim-s3 flex--center" anim={"2"} animS={"3"}>
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--cColor2 btn-box--shadow4 btn-box--filling2 fs--22" use={"page"} href={"/"} style={{"backgroundColor":"rgba(245,100,30,1)"}} target={null} content={"<span style=\"font-weight: 400;\">OPTIMALIZACE PROVOZU</span><br>"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--25" name={"067ugo6tslzq"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--style2" content={"<span style=\"font-weight: normal;\">PROČ SI VYBRAT NÁS?</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":841}} content={"<span style=\"font-weight: normal;\"><span style=\"color: rgb(0, 0, 0);\">Již 20 let zkušeností v oboru technická zařízení staveb<br><br>Autorizace v oboru technika prostředí staveb<br><br>Autocad / Revit <br><br>Energetické modelování&nbsp; / hodinové simulace&nbsp;<br><br>Řízení projektu, seznam závad, reporty</span></span><span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: normal;\"><br></span></span><span style=\"color: rgb(0, 0, 0); font-family: Raleway, sans-serif; font-size: 18px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400;\"><br>Měření tloušťky stěn potrubí s vyhodnocením očekávané životnosti</span><span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: normal;\"><br><br>Kalibrované měřící přístroje vzduch / voda / termovizní měření<br></span><br><span style=\"font-weight: normal;\">Neinvazivní ultrazvukové měření průtoku kapalin</span>&nbsp;<br><br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--25" name={"pracovnimista"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--style2" content={"<span style=\"font-weight: normal;\">VOLNÁ PRACOVNÍ MÍSTA</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":841}} content={"<span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: normal;\">Projektant vzduchotechniky<br><br>Projektant vytápění/chlazení<br><br>Projektant zdravotechniky</span><br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-kc4gbz --center --parallax pb--80" name={"mnajn1a1vcb"} style={{"paddingTop":352}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/9329/8f18f0a50e80411a8f56e514997b8458_s=350x_.JPEG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8f18f0a50e80411a8f56e514997b8458_s=660x_.JPEG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8f18f0a50e80411a8f56e514997b8458_s=860x_.JPEG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8f18f0a50e80411a8f56e514997b8458_s=1400x_.JPEG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8f18f0a50e80411a8f56e514997b8458_s=2000x_.JPEG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8f18f0a50e80411a8f56e514997b8458_s=660x_.JPEG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8f18f0a50e80411a8f56e514997b8458_s=1400x_.JPEG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/9329/8f18f0a50e80411a8f56e514997b8458_s=3000x_.JPEG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--08 pt--30" name={"kontaktni_form"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style2" content={"<span style=\"font-weight: normal;\">KONTAKTNÍ FORMULÁŘ</span>"}>
              </Title>

              <Text className="text-box pb--10" style={{"maxWidth":554}} content={"<span style=\"font-weight: normal;\">Napište nám&nbsp;<br><br><br><br><br><br><br><br><br><br><br></span><br>"}>
              </Text>

              <Image className="--left pl--02 pr--40" src={"https://cdn.swbpg.com/o/9329/89560b4e6f4f4d74bab7d2ebfbccebfa.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":206}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style2 --right" action={"/contact"} fields={[{"name":"Jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat zprávu","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"6efyxknzgh3"} style={{"backgroundColor":"rgba(245,100,30,1)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--20" content={"<span style=\"color: rgb(255, 255, 255); font-weight: normal;\">MEP Design &amp; Consulting s.r.o.</span><br>"}>
              </Title>

              <Text className="text-box fs--14" content={"<span style=\"color: rgb(255, 255, 255); font-weight: normal;\">Poličanská 1487, 190 16 Praha 9\n</span><br><span style=\"color: rgb(255, 255, 255);\">IČ:</span><span style=\"color: rgb(255, 255, 255); font-weight: normal;\"> 14091500, </span><span style=\"color: rgb(255, 255, 255);\">DIČ:</span><span style=\"color: rgb(255, 255, 255); font-weight: normal;\"> CZ14091500</span><br><span style=\"color: rgb(255, 255, 255); font-weight: normal;\">Zapsána u Městského soudu v Praze, oddíl C, vložka 360464</span><br><span style=\"color: rgb(255, 255, 255);\">Datová schránka:</span><span style=\"color: rgb(255, 255, 255); font-weight: normal;\"> jd528g5\n</span><br><span style=\"color: rgb(255, 255, 255);\">T:<span style=\"font-weight: normal;\"> +420 774 520 238<br></span>T<span style=\"font-weight: normal;\">: +420 777 156 793</span><br>E: <span style=\"font-weight: normal;\">info@mepdesign.cz<br></span>KANCELÁŘ:<span style=\"font-weight: normal;\"> Tuchorazská 1433, 282 01 Český Brod</span></span><span style=\"color: rgb(255, 255, 255); font-weight: normal;\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right fs--24" content={"<span style=\"color: rgb(255, 255, 255);\">M</span><span style=\"color: rgb(255, 255, 255); font-weight: normal;\">echanical, </span><span style=\"color: rgb(255, 255, 255);\">E</span><span style=\"color: rgb(255, 255, 255); font-weight: normal;\">lectrical  and  \n</span><span style=\"color: rgb(255, 255, 255);\">P</span><span style=\"color: rgb(255, 255, 255); font-weight: normal;\">lumbing Engineering</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"d1uhs8wdu0c"} style={{"backgroundColor":"rgba(245,100,30,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--12" content={"<span style=\"font-weight: normal; color: rgb(255, 255, 255);\">© 2022 MEP Design &amp; Consulting s.r.o. in Czech Republic. All Rights Reserved.</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}